* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}


:root {
  /* --color-primary: #21fc0d; */
  --color-primary: rgb(22,41,65);
  --color-white: #ffffff;
  --color-light: #d3cdd0;
  --color-black: #000000;
  --color-blue: #270d6e;

  /* GENERAL CONTAINER VARIABLES */
  --container-width-lg: 85%;
  --container-width-md: 90%;

  /* BORDER RADIUS VARIABLES */
  --border-radius-1: 1.2rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 0.5rem;

  /* GENERAL TRANSITION VARIABLE */
  --transition: all 300ms ease;
}


html {
  scroll-behavior: smooth;
  transition: var(--transition);
}

body {
  font-family: "Montserrat", sans-serif;
  line-height: 1.6;
  font-size: 0.9rem;
  background-color: #d3cdd0;
}

/* GENERAL CONTAINER CLASS */
.container {
  width: var(--container-width-lg);
  margin-inline: auto;
  max-width: 1920px;
}

/* GENERAL SECTION STYLES */
section {
  padding: 10rem 0;
  background: var(--color-light);
  transition: var(--transition);
}

section:nth-child(odd) {
  background: var(--color-white);
}


section > p {
  text-align: center;
  margin: 0.5rem auto 4rem;
  width: 40%;
}

/* GENERAL SECTION STYLES */
a {
  transition: var(--transition);
  color: white;
}

p,
small {
  color: var(--color-black);
}

/* GENERAL HEADING STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  color: var(--color-black);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  color: var(--color-blue);
  margin-left: 8%;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.1rem;
}

h5 {
  font-size: 0.95rem;
}

h6 {
  font-size: 0.8rem;
}

/* GENERAL IMAGES STYLES */
img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* GENERAL BUTTON STYLES */
.btn {
  width: fit-content;
  background: var(--color-primary);
  color: var(--color-white);
  padding: 0.6rem 1rem;
  border: 2px solid transparent;
  box-shadow: 3px 3px 9px .2px rgb(81, 93, 107);;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: rgb(58, 72, 88);
}

.btn.sm {
  padding: 0.5rem 1.2rem;
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 1rem;
  }

  .container {
    width: var(--container-width-md);
  }

  section {
    padding: 8rem 0;
  }

  section > p {
    width: 72%;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.4rem;
  }

  h2 {
    font-size: 1.8rem;
    margin-left: 0;
    text-align: center;
  }

  h3 {
    font-size: 1.4rem;
    
  }

  h4 {
    font-size: 1rem;
  }

  .btn {
    padding: 0.6rem 0.8rem;
  }

  section > p {
    width: var(--container-width-md);
  }
}
