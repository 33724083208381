nav {
  width: 100vw;
  height: 8rem;
  display: grid;
  place-items: center;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: var(--transition);
  border-bottom: 2px solid rgb(124,204,251);
}

.container.nav-container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  width: 12rem;

}

.nav-menu {
  display: flex;
  gap: 4rem;
}

.nav-menu li {
  font-size: 180%;
}

.nav-menu a.active,
.nav-menu a:active,
.nav-menu a:hover {
  color: #8a8a8a;
}

@media screen and (max-width: 1100px) {
  .nav-menu {
    gap: 2rem;
  }
  .nav-menu li {
    font-size: 150%;
  }
}

@media screen and (max-width: 930px) {

  .nav-menu li {
    font-size: 130%;
  }
  
}

@media screen and (max-width: 830px) {
  .container.nav-container {
    flex-direction: column;
  }

   .nav-logo {
    width: 9rem;
  }

}

@media screen and (max-width: 630px) {
  .nav-menu {  
    gap: .6rem;
    
  }

  .nav-menu li {
    font-size: 110%;
  }

}

