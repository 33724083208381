#specials {
  padding-top: 11rem;
}

.specials-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 4rem;
  margin-top: 3rem
}

.card.special {
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}


.special h4 {
  margin-bottom: 0.7rem;
}

@media screen and (max-width: 1024px) {
  .specials-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  #specials {
    padding-top: 9rem;
  }

  .specials-container {
    
    gap: 1.2rem;
    margin-top: 1rem;
  }

  .card.special {
    gap: 1.2rem;
  }
}
